import React from 'react';
import { HeroBullets } from '../components/hero'
import { FeaturesCards } from '../components/feature-cards';

export const Home = () => {
    return (<>
      <HeroBullets />
      <FeaturesCards />
    </>
    );
}