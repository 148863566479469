import React from 'react';
import { BrowserRouter as Router, createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import { MantineProvider } from '@mantine/core';
import { HeaderAction } from './components/header'
import { FooterSimple } from './components/footer'
import { Home } from './pages/home';
import { Portfolio } from './pages/portfolio';
import { About } from './pages/about';
import { Contact } from './pages/contact';

function App() {

  const navigationData = [
  {
    link: "/",
    label: "Home",
  },
  {
    link: "/contact",
    label: "Contact",
  },
  // {
  //   link: "/about",
  //   label: "About",
  // },
  // {
  //   link: "/portfolio",
  //   label: "Portfolio",
  // }
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  }
]);


return (
    <MantineProvider
      theme={{
        primaryColor: 'indigo',
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <div className="App">
        <HeaderAction links={navigationData} />
        <RouterProvider router={router} />
        <FooterSimple links={navigationData} />
      </div>
    </MantineProvider>
  );
}

export default App;
