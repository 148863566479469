
import React from 'react';
import { HeroBullets } from '../components/hero'
import { FeaturesCards } from '../components/feature-cards';
import { ContactUs } from '../components/contact';
import { Paper } from '@mantine/core';

export const Contact = () => {

    return (<Paper w="100%">
      <h1>Contact</h1>
      <ContactUs />
    </Paper>
    );
}