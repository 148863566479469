import {
    createStyles,
    Text,
    Title,
    SimpleGrid,
    TextInput,
    Textarea,
    Button,
    Group,
    ActionIcon,
    rem,
    Container,
} from '@mantine/core';
import {IconBrandTwitter, IconBrandYoutube, IconBrandInstagram} from '@tabler/icons-react';
import {ContactIconsList} from './contact-icons';
import {ChangeEventHandler, useState} from 'react';

const useStyles = createStyles((theme) => ({
    wrapper: {
        minHeight: 400,
        boxSizing: 'border-box',
        backgroundImage: `linear-gradient(-60deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
            theme.colors[theme.primaryColor][7]
        } 100%)`,
        borderRadius: theme.radius.md,
        padding: `calc(${theme.spacing.xl} * 2.5)`,

        [theme.fn.smallerThan('sm')]: {
            padding: `calc(${theme.spacing.xl} * 1.5)`,
        },
        display: 'flex',
        justifyContent: 'space-between'
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        color: theme.white,
        lineHeight: 1,
    },

    description: {
        color: theme.colors[theme.primaryColor][0],
        maxWidth: rem(300),

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },

    form: {
        backgroundColor: theme.white,
        padding: theme.spacing.xl,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.lg,
    },

    social: {
        color: theme.white,

        '&:hover': {
            color: theme.colors[theme.primaryColor][1],
        },
    },

    input: {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[4],
        color: theme.black,

        '&::placeholder': {
            color: theme.colors.gray[5],
        },
    },

    inputLabel: {
        color: theme.black,
    },

    control: {
        backgroundColor: theme.colors[theme.primaryColor][6],
    },
}));

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];
const initialMsg = {
    email: "",
    name: "",
    text: "",
};

export function ContactUs() {
    const {classes} = useStyles();

    const [value, setValue] = useState(initialMsg);

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setValue({
            ...value,
            [name]: value,
        });
    };

    const icons = social.map((Icon, index) => (
        <ActionIcon key={index} size={28} className={classes.social} variant="transparent">
            <Icon size="1.4rem" stroke={1.5}/>
        </ActionIcon>
    ));

    return (
        <Container>
            <div className={classes.wrapper}>
                <SimpleGrid cols={2} spacing={150} breakpoints={[{maxWidth: 'sm', cols: 1}]}>
                    <div>
                        <Title className={classes.title}>Contact us</Title>
                        <Text className={classes.description} mt="sm" mb={30}>
                            Leave your email and we will get back to you within 24 hours
                        </Text>

                        <ContactIconsList variant="white"/>

                        {/*<Group mt="xl">{icons}</Group>*/}
                    </div>
                    <div className={classes.form}>
                        <TextInput
                            label="Email"
                            placeholder="your@email.com"
                            required
                            classNames={{input: classes.input, label: classes.inputLabel}}
                            value={value.email}
                            onChange={handleInputChange}
                        />
                        <TextInput
                            label="Name"
                            placeholder="John Doe"
                            mt="md"
                            classNames={{input: classes.input, label: classes.inputLabel}}
                            value={value.name}
                            onChange={handleInputChange}
                        />
                        <Textarea
                            required
                            label="Your message"
                            placeholder="I want to order your goods"
                            minRows={4}
                            mt="md"
                            classNames={{input: classes.input, label: classes.inputLabel}}
                            value={value.text}
                            onChange={handleInputChange}
                        />

                        <Group position="right" mt="md">
                            {/*<Button className={classes.control} onClick={(e) => {*/}
                            {/*    window.location.href = "mailto:07_fennel.verbal@icloud.com?subject=Subject&body=Body%20goes%20here";*/}
                            {/*    e.preventDefault();*/}
                            {/*}}>Send message</Button>*/}
                            <a href={"mailto:07_fennel.verbal@icloud.com?subject="+ value.name + "&body=" + value.text}>
                                <Button className={classes.control} >Send message</Button>
                            </a>
                        </Group>
                    </div>
                </SimpleGrid>
            </div>
        </Container>
    );
}