

import React from 'react';
import { HeroBullets } from '../components/hero'
import { FeaturesCards } from '../components/feature-cards';

export const Portfolio = () => {
    return (<>
    <h1>Portfolio</h1>
      <HeroBullets />
      <FeaturesCards />
    </>
    );
}